<template>
  <div id="admin-settings">
    <div class="top-menu">
      <div class="left">Genel Ayarlar</div>
      <div class="right">
        <ul>
          <li :class="{ active: activeTab == 0 ? true : false }" @click="activeTab = 0"><i class="icon-details"></i>Site</li>
          <li :class="{ active: activeTab == 1 ? true : false }" @click="activeTab = 1"><i class="icon-upload"></i>Görsel</li>
          <li :class="{ active: activeTab == 2 ? true : false }" @click="activeTab = 2"><i class="icon-status"></i>Sosyal Medya</li>
          <li><i class="icon-backtime"></i>History</li>
          <li><i class="icon-task"></i>Notes</li>
          <li><i class="menu-icon-chat"></i>Messages</li>
        </ul>
      </div>
    </div>
    <div class="middle">
      <p>Genel Ayarlar</p>
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
          <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
          <el-breadcrumb-item>Ayarlar</el-breadcrumb-item>
          <el-breadcrumb-item>Genel Ayarlar</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="settings-body"></div>

      <div class="settings-content-0" v-if="activeTab == 0">
        <div>
          <label>Site Title</label>
          <el-input></el-input>
        </div>

        <div>
          <label>Site Author</label>
          <el-input></el-input>
        </div>

        <div>
          <label>Description</label>
          <el-input type="textarea" :rows="5" v-model="textarea"> </el-input>
        </div>

        <div>
          <label>Meta Keywords</label>
          <el-input type="textarea" :rows="5" v-model="textarea"> </el-input>
        </div>

        <div class="buttons">
          <el-button class="save">Kaydet</el-button>
          <el-button class="cancel">İptal</el-button>
        </div>
      </div>
      <div class="settings-content-1" v-if="activeTab == 1">
        <div class="items">
          <div>
            <label>Favicon</label>
            <div class="photo"></div>
          </div>
          <div>
            <label>Logo</label>
            <el-upload class="upload-demo" drag :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" multiple>
              <i class="icon-Upload2"></i>
              <div class="el-upload__text">Drag and Drop or <em>Browse</em> to upload</div>
            </el-upload>
          </div>
        </div>
        <div class="buttons">
          <el-button class="save">Kaydet</el-button>
          <el-button class="cancel">İptal</el-button>
        </div>
      </div>

      <div class="settings-content-2" v-if="activeTab == 2"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style lang="less">
#admin-settings {
  .top-menu {
    background-color: #fff;
    display: flex;
    height: 64px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;

    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      padding-left: 26px;
      line-height: 23px;
      color: #44566c;
      width: 230px;
    }
    .right {
      ul {
        display: flex;
        gap: 70px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          list-style: none;
          flex-shrink: 0;
          &.active {
            position: relative;
            &::after {
              display: block;
              content: "";
              height: 2px;
              width: 100%;
              background: #0081ff;
              left: 0;
              bottom: 0;
              border-radius: 0 5px 5px 0;
              position: absolute;
            }
          }
        }
        i {
          background: #8697a8;
        }
      }
    }
  }
  .middle {
    padding: 29px 38px;
      ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #eaedf0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #8697a8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #8697a8;
    }

    p {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
    }
  }
  .settings-content-0 {
  
    overflow: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    background-color: #fff;
    width: 100%;
    height: 550px;
    border-radius: 20px;
    filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
    div {
      display: flex;
      align-items: center;

      label {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
        width: 250px;
      }
      input,
      textarea {
        width: 100%;
        background: #f8fafb;
        border: none;
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      gap: 24px;
      .save {
        background: #0081ff;
        color: #fff;
        width: 167px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        border: none;
        filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
      }
      .cancel {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        width: 110px;
        color: #0081ff;
        background-color: #d9ecff;
        border: none;
      }
    }
  }
  .settings-content-1 {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    background-color: #fff;
    width: 100%;
    height: 550px;
    border-radius: 20px;
    filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
    .items {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        gap: 33px;
        flex-direction: column;
        align-items: center;
        .photo {
          width: 171px;
          height: 212px;
          border-radius: 40px;
          overflow: hidden;
          box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);

          img {
            width: 100%;
          }
        }
        .el-upload-dragger {
          width: 420px;
          height: 100px;
          background: #eaedf0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .el-upload__text {
            margin: 0;
          }

          .el-icon-upload:before {
            width: 30px !important;
            height: 30px !important;
            color: #44566c;
          }
        }
        .el-upload__text {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #44566c;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      gap: 24px;
      .save {
        background: #0081ff;
        color: #fff;
        width: 167px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        border: none;
        filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
      }
      .cancel {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        width: 110px;
        color: #0081ff;
        background-color: #d9ecff;
        border: none;
      }
    }
  }
}
</style>
